.custom_subheader {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1rem;
    padding: 0px 12px 0px 15px;
    border-top: 1px solid #C9CCCF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.extra_padding {
    padding: 0px 12px 8px 15px;
}


.title_wrap {
    margin: 16px;
}

.chevron_close {
    transform: rotate(0deg);
    height: 14px;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.chevron_open {
    transform: rotate(270deg);
    height: 14px;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.field_wrap {
    margin: 0px 16px;
}

.button_wrap {
    margin-top: 19px;
}

.content_divider {
    border-top: 1px solid #C9CCCF;
    height: 0px !important;
}

.title {
    padding: 14px 0px;
}