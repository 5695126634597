.label_wrap {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 13px;
}

.fields_wrap {
    width: 100%;
    margin-bottom: 15px;
}

.fields_wrap>div {
    width: 48%;
    display: inline-block;
}

.fields_wrap>div:nth-child(odd) {
    margin-right: 6px;
}

.input_field {
    margin-bottom: 4px;
}

