.titleContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleText{
    margin-right: 10px;
}

.avatarImage{
    margin-right: 20px;
}


