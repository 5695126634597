.input_wrap {
    margin-bottom: 16px;
}

.input_wrap label{
    font-size: 14px;
}

.select_wrap {
    font-size: 14px !important;
}

.help_text {
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
    margin-top: 4px;
}

.help_text>ul {
    margin-bottom: 6px;
    padding-left: 25px;
}

.help_text a:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.help_text>div {
    margin-bottom: 7px;
}

.bolder_text {
    font-weight: 600;
}

.link_wrap {
    margin-top: 10px;
    text-decoration: none;
    font-size: 14px !important;
    font-weight: 400;
    color: #1879B9 !important;
    cursor: pointer;
}

.link_wrap:hover {
    color: var(--p-interactive-hovered) !important;
    text-decoration: none;
}

.input_overlay{
    position: relative;
    z-index: 1;
}