.checkbox_wrap {
    margin-bottom: 16px;
}

.checkbox_field {
    display: flex;
    justify-content: space-between;
}

.help_text {
    margin-left: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #6D7175;
}

